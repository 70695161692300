<template>
    <div class="card">
        <div class="card-header">
            <h3 class="title">
                {{$t("top_page.inquiry.view_submit_title")}}
            </h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">
                    <div 
                        :class="`alert alert-${msgType}`" 
                        role="alert" 
                        v-if="msg">
                        {{msg}}
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="group-frm">
                        <div class="row mb-0">
                            <div class="col-md-3">
                                <label class="lbl">{{ $t('top_page.inquiry.type') }}</label>
                            </div>
                            <div class="col-md-8">
                                {{inquiry.type}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="group-frm">
                        <div class="row mb-0">
                            <div class="col-md-3">
                                <label class="lbl">{{ $t('top_page.inquiry.name') }}</label>
                            </div>
                            <div class="col-md-8">
                                {{inquiry.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="group-frm">
                        <div class="row mb-0">
                            <div class="col-md-3">
                                <label class="lbl">{{ $t('top_page.inquiry.email') }}</label>
                            </div>
                            <div class="col-md-8">
                                {{inquiry.email}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="group-frm">
                        <div class="row mb-0">
                            <div class="col-md-3">
                                <label  class="lbl">{{ $t('top_page.inquiry.detail') }}</label>
                            </div>
                            <div class="col-md-8 text-pre-line">
                               {{inquiry.detail}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 text-center">
                    <div class="group-frm">
                        <div>
                            <button 
                                @click="handleSubmit"
                                class="btn btn-primary btn-inquiry" 
                                :disabled="submit">
                                {{$t('top_page.inquiry.submit')}}{{submit?'...':''}}
                            </button>
                        </div>
                        <div>
                            <button 
                                class="btn btn-danger btn-inquiry" 
                                @click="handleStep(1)"
                                :disabled="submit">
                                {{$t('top_page.inquiry.go_back')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { mapActions } from 'vuex';
export default {
    name: "ViewForm",
    data(){
        return {
            msgType: "danger",
            msg: "",
            submit: false,
        }
    },
    props: {
       inquiry: {}
    },
    methods: {
        ...mapActions({
            inquiryToAdmin: "common/inquiryToAdmin",
        }),
        handleSubmit() {
            if (this.submit)
                return;
                this.submit = true;
                this.inquiryToAdmin({
                    data: this.inquiry,
                    cb: (response) => {
                        let { meta, data } = response;
                        if (meta && meta.code == 200) {
                            this.success = 1;
                            this.msg = "";
                            this.handleStep(3);
                        }
                        else {
                            if (data && data.message) {
                                this.msg = data.message;
                            }
                            else {
                                this.msg = this.$t("top_page.inquiry.failed");
                            }
                            this.msgType = "danger";
                        }
                    }
                });
                this.submit = false;
                window.scrollTo({ top: 0, behavior: "smooth" });
        },
        handleStep(step){
            this.$emit("change", step);
        }
    }
}
</script>