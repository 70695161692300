<template lang="">
    <div class="card">
        <div class="card-header">
            <h3 class="title">
                {{$t("top_page.inquiry.completed_title")}}
            </h3>
        </div>
        <div class="card-body">
            <p class="text-pre-line text-center pt-5">
                {{$t("top_page.inquiry.completed_text")}}
            </p>
            <p class="text-center mt-3 mb-3">
                <router-link to="/">
                    <button class="btn btn-primary">
                        TOP へ
                    </button>
                </router-link>
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: "SubmitCompleted"
}
</script>